import * as types from './actionTypes';
import * as APIString from '~constants/apiString';
import { getTenantFeatureFlagsWithSsr } from '~constants/tenantConfig/tenantFeatureFlags';
import { getConfigByKey } from '~features/tenantConfig/services';
import * as utils from '~root/utils/utils';
import { isNode } from '~utils/ssr';

const bannerExtraObject = {
  images: {
    banner: '/app/assets/img/odv/banner/banner_1920x677_update.jpg',
    thumbnail: '/app/assets/img/odv/banner/Thumbnail_1920x1080_toyota_bz4x_vn.jpg',
    banner_190_67_ratio: '/app/assets/img/odv/banner/banner_1920x677_update.jpg',
    rectangle_banner: '/app/assets/img/odv/banner/Rectangle_1920x1920_toyota_bz4x_vn_update.jpg',
  },
  bannerExtra: true,
  link: 'https://www.contentpromo.ondemandkorea.com/toyota-bz4x-2024-odv-vi',
};

export const initialState = {
  menu: [],
  isHomePage: false,
  page: null,
  pageSlug: null,
  isLoadingPage: 0,
  isRefreshPage: false,
  ribbonTarget: '',
  removeProcess: {
    isOpen: false,
    items: {},
    removeId: '',
  },
  topTenContents: [],
  reactedContents: [],
};

// const SLUG_TENANTS = [];
// const SLUG_RIBBON_PAGE = [];
const SLUG_TENANTS_PLATFORM = ['odv', 'sctv'];
const TENANT_PAGES_HAVE_EXTRA_BANNERS = new Set([
  'home_page',
  'home-page',
  'trang-chu-beta-test-0fe8033c',
  'beta-phim-bo-b4840b0f',
  'beta-phim-le-70fd2396',
  'new-web-trang-chu-69e39ae6',
  'new-web-phim-bo-60aa6fb4',
  'new-web-phim-le-3f405f96',
]);

export default function home(state = initialState, action = {}) {
  switch (action.type) {
    case types.GET_MENU: {
      const menu = Array.isArray(action.menu) ? action.menu : [];
      if (getConfigByKey('features.amasianTVMenuItem')) {
        menu.push({
          id: 'https://amasian.tv',
          name: 'Amasian TV',
          slug: 'https://amasian.tv',
          isNewTab: true,
        });
      }
      return {
        ...state,
        menu: Array.isArray(action.menu) ? action.menu : [],
      };
    }

    case types.GET_PAGE:
      let isHomePage = false;
      let bannerExtra = [];
      // let topTenExtra = [];
      // const ribbonCustomize = (action.page && action.page.ribbons) || [];
      // const ribbonVerticalCustomize = (action.page && action.page.side_navigation_section) || [];
      const tenantFeatureFlagsWithSsr = getTenantFeatureFlagsWithSsr(utils);
      if (state.menu.length > 0 && action.page && state.menu[0].slug === action.page.pageSlug) {
        isHomePage = true;
      }
      const pageSlug = (action.page && action.page.pageSlug) || '';

      const isBannerHardcode =
        (getConfigByKey('ui_theme') === 'odv' && getConfigByKey('features.extraBannerAds')) ||
        (tenantFeatureFlagsWithSsr.extraBannerAds && isNode());

      if (isBannerHardcode && TENANT_PAGES_HAVE_EXTRA_BANNERS.has(pageSlug)) {
        bannerExtra = [bannerExtraObject];
        // topTenExtra = [
        //   {
        //     ...obj,
        //     duration: null,
        //     has_free_content: true,
        //     id: '',
        //     is_new_release: false,
        //     is_premium: false,
        //     runtime: 0,
        //     slug: '',
        //     title: '',
        //     type: 1,
        //     video_source: 0,
        //   },
        // ];
      }
      // ribbonVerticalCustomize.forEach(element => {
      //   if (SLUG_RIBBON_PAGE.includes(element.slug)) {
      //     element.items = [...topTenExtra, ...element.items];
      //   }
      // });
      // ribbonCustomize.forEach(element => {
      //     element.items = [...topTenExtra, ...element.items];
      // });

      return {
        ...state,
        page: {
          ...action.page,
          banners:
            (SLUG_TENANTS_PLATFORM.includes(getConfigByKey('ui_theme')) || isBannerHardcode) &&
            action.page.banners &&
            action.page.banners.length > 0
              ? [...bannerExtra, ...action.page.banners]
              : [...action.page.banners],
        },
        // isLoadingPage: false,
        isHomePage,
        pageSlug: action.page.pageSlug,
      };
    case types.GET_PAGE_REQUEST:
      return {
        ...state,
        isLoadingPage: state.isLoadingPage + 1,
      };
    case types.REFRESH_PAGE:
      return {
        ...state,
        isRefreshPage: action.isRefreshPage,
      };
    case types.GET_FINAL:
      const newLoading = state.isLoadingPage - 1;
      return {
        ...state,
        isLoadingPage: newLoading > 0 ? newLoading : 0,
      };
    case types.GET_RIBBON_TARGET:
      return {
        ...state,
        ribbonTarget: action.idRibbonTarget,
      };
    case types.UPDATE_STATUS_REMOVE_PROCESS:
      return {
        ...state,
        removeProcess: action.payload,
      };
    case types.UPDATE_SEARCH:
      return {
        ...state,
        page: {
          ...state.page,
          search: action.queryParams,
        },
      };
    case types.UPDATE_PAGE_SLUG:
      return {
        ...state,
        pageSlug: action.payload,
      };
    case types.SET_TOP_TEN_CONTENTS:
      return {
        ...state,
        topTenContents: action.payload,
      };
    case types.UPDATE_REACTED_CONTENT:
      return {
        ...state,
        reactedContents: action.payload,
      };
    case types.UPDATE_SSR:
      return {
        ...state,
        isSsr: action.payload,
      };
    case types.UPDATE_RIBBONS:
      return {
        ...state,
        page: {
          ...state.page,
          ribbons: action.ribbons,
        },
      };
    default:
      return state;
  }
}
