import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import profilePageId from '../../../constants/ids/profilePageId';
import { getConfigByKey } from '../../tenantConfig/services';
import { setTermsAndConditions } from '~components/global/globalServices';

const termsOfUseLink = getConfigByKey('termsOfUseLink');

const TermOfUse = props => {
  const { modalShow, documents, showModal } = props;
  const termOfUseContent = documents.termsAndConditions.content;

  useEffect(() => {
    const { dpSetTermsAndConditions } = props;
    if (termsOfUseLink || !modalShow) {
      return;
    }
    dpSetTermsAndConditions();
  }, [modalShow]);

  if (termsOfUseLink || !termOfUseContent) {
    return null;
  }

  return (
    <Modal
      show={modalShow}
      onHide={() => showModal()}
      aria-labelledby="contained-modal-title-vcenter"
      style={{ zIndex: 10000 }}
    >
      <Modal.Header closeButton style={{ border: 'none' }} />
      <Modal.Body style={{ height: 700, overflow: 'auto' }}>
        <div
          style={{ padding: 50, paddingTop: 0 }}
          dangerouslySetInnerHTML={{
            __html: termOfUseContent || '',
          }}
        ></div>
      </Modal.Body>
    </Modal>
  );
};

TermOfUse.defaultProps = {
  showModal: () => {},
  modalShow: false,
};

const mapDispatchToProps = {
  dpSetTermsAndConditions: () => setTermsAndConditions(),
};

const mapStateToProps = state => ({
  documents: state.global.documents,
});

export default connect(mapStateToProps, mapDispatchToProps)(TermOfUse);
